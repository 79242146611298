export const DeucesWildTitle = () => {
  return (
    <g id="Deuces wild">
      <g
        aria-label="DEUCES WILD"
        id="text4347-9"
        style={{ stroke: "#000000", strokeWidth: 42 }}
        transform="translate(-0.8482565,-11.02547)"
      >
        <g
          id="g5533-3"
          transform="translate(-30,14)"
          style={{ stroke: "#000000", strokeWidth: 42 }}
        >
          <path d="m 88.507233,126.14393 v -9.84378 l 8.072942,-1.82292 V 62.081228 l -8.072942,-1.875005 v -9.895865 h 8.072942 26.302165 q 10.20837,0 18.02089,4.791682 7.81252,4.739598 12.23962,13.177124 4.4271,8.385443 4.4271,19.322977 v 1.302088 q 0,10.885451 -4.37502,19.322981 -4.37501,8.38544 -12.18753,13.17712 -7.81253,4.7396 -18.02089,4.7396 z m 24.739657,-12.65629 h 8.8542 q 6.09377,0 10.20836,-3.12501 4.16668,-3.12501 6.30211,-8.64586 2.1875,-5.572935 2.1875,-12.812541 v -1.354171 q 0,-7.291689 -2.1875,-12.81254 -2.13543,-5.520851 -6.30211,-8.593777 -4.11459,-3.12501 -10.20836,-3.12501 h -8.8542 z" />
          <path d="m 163.87206,126.14393 v -9.84378 l 8.02085,-1.61459 V 61.820811 l -8.02085,-1.614588 v -9.895865 h 59.68768 v 21.1459 h -12.65629 l -0.57292,-8.33336 h -21.7709 v 17.395888 h 24.948 V 93.38341 h -24.948 v 19.94798 h 22.86466 l 0.57292,-8.64586 h 12.55212 v 21.4584 z" />
          <path d="m 270.22655,127.23768 q -9.37502,0 -16.45838,-3.12501 -7.03127,-3.12501 -10.88545,-9.11461 -3.85418,-6.04169 -3.85418,-14.68755 V 61.820811 l -7.96877,-1.614588 v -9.895865 h 32.60426 v 9.895865 l -7.96877,1.614588 v 38.489699 q 0,4.79169 1.71875,7.96878 1.71876,3.17709 4.94794,4.7396 3.28126,1.5625 7.81252,1.5625 4.68752,0 7.96878,-1.5625 3.28126,-1.56251 4.94793,-4.7396 1.71875,-3.17709 1.71875,-7.96878 V 61.820811 l -7.91669,-1.614588 v -9.895865 h 32.60427 v 9.895865 l -8.02086,1.614588 v 38.489699 q 0,8.5417 -3.95834,14.58338 -3.90627,6.04169 -10.93754,9.21878 -6.97919,3.12501 -16.35422,3.12501 z" />
          <path d="m 350.12264,127.23768 q -10.93754,0 -19.2709,-4.47918 -8.33336,-4.53126 -12.96879,-12.91671 -4.63543,-8.38544 -4.63543,-20.104225 v -2.968759 q 0,-10.989618 4.37502,-19.427144 4.37501,-8.48961 12.44795,-13.281292 8.12503,-4.843765 19.2709,-4.843765 7.81252,0 14.73962,2.135423 6.97919,2.135424 12.65629,6.927105 V 77.185442 H 364.49768 L 362.83101,65.83124 q -1.77084,-1.19792 -3.75001,-2.08334 -1.97917,-0.885419 -4.27085,-1.354171 -2.29167,-0.468751 -4.94793,-0.468751 -6.14585,0 -10.62503,2.968759 -4.47918,2.968759 -6.92711,8.48961 -2.44792,5.520851 -2.44792,13.281292 v 3.072926 q 0,7.500023 2.50001,13.125045 2.55209,5.57293 7.18752,8.64586 4.63543,3.07292 10.78128,3.07292 3.22918,0 6.45836,-0.67708 3.22917,-0.72917 6.04168,-1.87501 l 1.71876,-10.67712 h 12.23962 v 18.54173 q -4.47918,3.02084 -11.30212,5.20835 -6.77086,2.13542 -15.36463,2.13542 z" />
          <path d="m 382.7269,126.14393 v -9.84378 l 8.02086,-1.61459 V 61.820811 l -8.02086,-1.614588 v -9.895865 h 59.68768 v 21.1459 H 429.7583 l -0.57292,-8.33336 h -21.7709 v 17.395888 h 24.94799 V 93.38341 h -24.94799 v 19.94798 h 22.86465 l 0.57292,-8.64586 h 12.55212 v 21.4584 z" />
          <path d="m 482.88346,127.23768 q -8.80211,0 -15.83338,-1.82292 -7.03127,-1.82292 -13.90629,-6.56252 V 99.841763 h 12.86462 l 1.61459,10.468787 q 1.97917,1.92709 6.14585,3.17709 4.16668,1.25 9.11461,1.25 3.8021,0 6.40627,-0.98958 2.60418,-1.04167 3.95835,-2.91668 1.35417,-1.92709 1.35417,-4.53126 0,-2.50001 -1.25001,-4.4271 -1.19792,-1.979173 -4.01042,-3.645845 -2.81251,-1.666672 -7.60419,-3.281261 -9.37503,-2.864592 -15.4688,-6.041685 -6.04169,-3.229177 -9.06253,-7.604191 -2.96876,-4.427097 -2.96876,-10.833367 0,-6.197936 3.48959,-10.88545 3.54168,-4.739599 9.68753,-7.44794 6.14586,-2.708342 14.16671,-2.812509 9.01045,-0.208334 15.93755,1.927089 6.92711,2.135424 12.08338,6.145853 V 75.050019 H 497.20642 L 495.12308,64.78957 q -1.875,-1.093753 -5.20835,-1.927089 -3.28126,-0.833336 -7.29169,-0.833336 -3.43751,0 -6.09376,0.989586 -2.60418,0.937503 -4.16668,2.864593 -1.51043,1.875006 -1.51043,4.635431 0,2.23959 1.30209,4.010429 1.30209,1.770839 4.47918,3.437511 3.1771,1.614588 8.80211,3.593761 12.70838,3.645845 19.2709,9.375029 6.6146,5.729185 6.6146,15.260465 0,6.6146 -3.59376,11.3542 -3.54168,4.68752 -9.94795,7.18752 -6.40627,2.50001 -14.89588,2.50001 z" />
        </g>
        <g
          id="g5525-6"
          transform="translate(-20,-22)"
          style={{ stroke: "#000000", strokeWidth: 42 }}
        >
          <path d="m 175.11555,297.02547 -24.13417,-89.59005 -11.24352,-1.07423 v -13.6068 h 44.40115 v 13.6068 l -11.45836,1.64714 11.81643,48.76965 2.00521,8.09246 h 0.42969 l 2.22006,-8.09246 20.48182,-64.02359 h 16.32816 l 21.41281,66.31526 2.07683,6.51694 h 0.42969 l 1.71875,-6.51694 12.38935,-51.06132 -11.88805,-1.64714 v -13.6068 h 43.54177 v 13.6068 l -10.52737,1.07423 -24.7787,89.59005 h -20.62505 l -18.90629,-57.57826 -2.29167,-8.09247 h -0.42969 l -2.79298,8.37893 -19.19275,57.2918 z" />
          <path d="m 300.72781,297.02547 v -13.53519 l 11.02868,-2.22006 v -72.68897 l -11.02868,-2.22006 v -13.6068 h 44.83084 v 13.6068 l -10.95706,2.22006 v 72.68897 l 10.95706,2.22006 v 13.53519 z" />
          <path d="m 352.50528,297.02547 v -13.53519 l 11.02867,-2.22006 v -72.68897 l -11.02867,-2.22006 v -13.6068 h 11.02867 22.91672 12.81904 v 13.6068 l -12.81904,2.22006 v 70.8986 h 27.21361 l 0.78776,-14.96748 h 17.474 v 32.5131 z" />
          <path d="m 438.22812,297.02547 v -13.53519 l 11.10029,-2.50652 v -72.04443 l -11.10029,-2.57814 v -13.6068 h 11.10029 36.16544 q 14.0365,0 24.77871,6.58856 10.74221,6.51694 16.82946,18.11853 6.08726,11.52998 6.08726,26.56907 v 1.79037 q 0,14.96748 -6.01564,26.56907 -6.01564,11.52998 -16.75785,18.11854 -10.74222,6.51694 -24.77871,6.51694 z m 34.01701,-17.40239 h 12.1745 q 8.37893,0 14.03649,-4.29688 5.72918,-4.29689 8.66539,-11.88805 3.00782,-7.66278 3.00782,-17.61723 v -1.86198 q 0,-10.02607 -3.00782,-17.61723 -2.93621,-7.59116 -8.66539,-11.81643 -5.65756,-4.29689 -14.03649,-4.29689 h -12.1745 z" />
        </g>
      </g>
      <g
        aria-label="DEUCES
  WILD"
        id="text4347"
        style={{ stroke: "#0296ff", strokeWidth: 35.6 }}
        transform="translate(-0.8482565,-11.02547)"
      >
        <g
          id="g5533"
          transform="translate(-30,14)"
          style={{ stroke: "#0296ff", strokeWidth: 35.6 }}
        >
          <path d="m 88.507233,126.14393 v -9.84378 l 8.072942,-1.82292 V 62.081228 l -8.072942,-1.875005 v -9.895865 h 8.072942 26.302165 q 10.20837,0 18.02089,4.791682 7.81252,4.739598 12.23962,13.177124 4.4271,8.385443 4.4271,19.322977 v 1.302088 q 0,10.885451 -4.37502,19.322981 -4.37501,8.38544 -12.18753,13.17712 -7.81253,4.7396 -18.02089,4.7396 z m 24.739657,-12.65629 h 8.8542 q 6.09377,0 10.20836,-3.12501 4.16668,-3.12501 6.30211,-8.64586 2.1875,-5.572935 2.1875,-12.812541 v -1.354171 q 0,-7.291689 -2.1875,-12.81254 -2.13543,-5.520851 -6.30211,-8.593777 -4.11459,-3.12501 -10.20836,-3.12501 h -8.8542 z" />
          <path d="m 163.87206,126.14393 v -9.84378 l 8.02085,-1.61459 V 61.820811 l -8.02085,-1.614588 v -9.895865 h 59.68768 v 21.1459 h -12.65629 l -0.57292,-8.33336 h -21.7709 v 17.395888 h 24.948 V 93.38341 h -24.948 v 19.94798 h 22.86466 l 0.57292,-8.64586 h 12.55212 v 21.4584 z" />
          <path d="m 270.22655,127.23768 q -9.37502,0 -16.45838,-3.12501 -7.03127,-3.12501 -10.88545,-9.11461 -3.85418,-6.04169 -3.85418,-14.68755 V 61.820811 l -7.96877,-1.614588 v -9.895865 h 32.60426 v 9.895865 l -7.96877,1.614588 v 38.489699 q 0,4.79169 1.71875,7.96878 1.71876,3.17709 4.94794,4.7396 3.28126,1.5625 7.81252,1.5625 4.68752,0 7.96878,-1.5625 3.28126,-1.56251 4.94793,-4.7396 1.71875,-3.17709 1.71875,-7.96878 V 61.820811 l -7.91669,-1.614588 v -9.895865 h 32.60427 v 9.895865 l -8.02086,1.614588 v 38.489699 q 0,8.5417 -3.95834,14.58338 -3.90627,6.04169 -10.93754,9.21878 -6.97919,3.12501 -16.35422,3.12501 z" />
          <path d="m 350.12264,127.23768 q -10.93754,0 -19.2709,-4.47918 -8.33336,-4.53126 -12.96879,-12.91671 -4.63543,-8.38544 -4.63543,-20.104225 v -2.968759 q 0,-10.989618 4.37502,-19.427144 4.37501,-8.48961 12.44795,-13.281292 8.12503,-4.843765 19.2709,-4.843765 7.81252,0 14.73962,2.135423 6.97919,2.135424 12.65629,6.927105 V 77.185442 H 364.49768 L 362.83101,65.83124 q -1.77084,-1.19792 -3.75001,-2.08334 -1.97917,-0.885419 -4.27085,-1.354171 -2.29167,-0.468751 -4.94793,-0.468751 -6.14585,0 -10.62503,2.968759 -4.47918,2.968759 -6.92711,8.48961 -2.44792,5.520851 -2.44792,13.281292 v 3.072926 q 0,7.500023 2.50001,13.125045 2.55209,5.57293 7.18752,8.64586 4.63543,3.07292 10.78128,3.07292 3.22918,0 6.45836,-0.67708 3.22917,-0.72917 6.04168,-1.87501 l 1.71876,-10.67712 h 12.23962 v 18.54173 q -4.47918,3.02084 -11.30212,5.20835 -6.77086,2.13542 -15.36463,2.13542 z" />
          <path d="m 382.7269,126.14393 v -9.84378 l 8.02086,-1.61459 V 61.820811 l -8.02086,-1.614588 v -9.895865 h 59.68768 v 21.1459 H 429.7583 l -0.57292,-8.33336 h -21.7709 v 17.395888 h 24.94799 V 93.38341 h -24.94799 v 19.94798 h 22.86465 l 0.57292,-8.64586 h 12.55212 v 21.4584 z" />
          <path d="m 482.88346,127.23768 q -8.80211,0 -15.83338,-1.82292 -7.03127,-1.82292 -13.90629,-6.56252 V 99.841763 h 12.86462 l 1.61459,10.468787 q 1.97917,1.92709 6.14585,3.17709 4.16668,1.25 9.11461,1.25 3.8021,0 6.40627,-0.98958 2.60418,-1.04167 3.95835,-2.91668 1.35417,-1.92709 1.35417,-4.53126 0,-2.50001 -1.25001,-4.4271 -1.19792,-1.979173 -4.01042,-3.645845 -2.81251,-1.666672 -7.60419,-3.281261 -9.37503,-2.864592 -15.4688,-6.041685 -6.04169,-3.229177 -9.06253,-7.604191 -2.96876,-4.427097 -2.96876,-10.833367 0,-6.197936 3.48959,-10.88545 3.54168,-4.739599 9.68753,-7.44794 6.14586,-2.708342 14.16671,-2.812509 9.01045,-0.208334 15.93755,1.927089 6.92711,2.135424 12.08338,6.145853 V 75.050019 H 497.20642 L 495.12308,64.78957 q -1.875,-1.093753 -5.20835,-1.927089 -3.28126,-0.833336 -7.29169,-0.833336 -3.43751,0 -6.09376,0.989586 -2.60418,0.937503 -4.16668,2.864593 -1.51043,1.875006 -1.51043,4.635431 0,2.23959 1.30209,4.010429 1.30209,1.770839 4.47918,3.437511 3.1771,1.614588 8.80211,3.593761 12.70838,3.645845 19.2709,9.375029 6.6146,5.729185 6.6146,15.260465 0,6.6146 -3.59376,11.3542 -3.54168,4.68752 -9.94795,7.18752 -6.40627,2.50001 -14.89588,2.50001 z" />
        </g>
        <g
          id="g5525"
          transform="translate(-20,-22)"
          style={{ stroke: "#0296ff", strokeWidth: 35.6 }}
        >
          <path d="m 175.11555,297.02547 -24.13417,-89.59005 -11.24352,-1.07423 v -13.6068 h 44.40115 v 13.6068 l -11.45836,1.64714 11.81643,48.76965 2.00521,8.09246 h 0.42969 l 2.22006,-8.09246 20.48182,-64.02359 h 16.32816 l 21.41281,66.31526 2.07683,6.51694 h 0.42969 l 1.71875,-6.51694 12.38935,-51.06132 -11.88805,-1.64714 v -13.6068 h 43.54177 v 13.6068 l -10.52737,1.07423 -24.7787,89.59005 h -20.62505 l -18.90629,-57.57826 -2.29167,-8.09247 h -0.42969 l -2.79298,8.37893 -19.19275,57.2918 z" />
          <path d="m 300.72781,297.02547 v -13.53519 l 11.02868,-2.22006 v -72.68897 l -11.02868,-2.22006 v -13.6068 h 44.83084 v 13.6068 l -10.95706,2.22006 v 72.68897 l 10.95706,2.22006 v 13.53519 z" />
          <path d="m 352.50528,297.02547 v -13.53519 l 11.02867,-2.22006 v -72.68897 l -11.02867,-2.22006 v -13.6068 h 11.02867 22.91672 12.81904 v 13.6068 l -12.81904,2.22006 v 70.8986 h 27.21361 l 0.78776,-14.96748 h 17.474 v 32.5131 z" />
          <path d="m 438.22812,297.02547 v -13.53519 l 11.10029,-2.50652 v -72.04443 l -11.10029,-2.57814 v -13.6068 h 11.10029 36.16544 q 14.0365,0 24.77871,6.58856 10.74221,6.51694 16.82946,18.11853 6.08726,11.52998 6.08726,26.56907 v 1.79037 q 0,14.96748 -6.01564,26.56907 -6.01564,11.52998 -16.75785,18.11854 -10.74222,6.51694 -24.77871,6.51694 z m 34.01701,-17.40239 h 12.1745 q 8.37893,0 14.03649,-4.29688 5.72918,-4.29689 8.66539,-11.88805 3.00782,-7.66278 3.00782,-17.61723 v -1.86198 q 0,-10.02607 -3.00782,-17.61723 -2.93621,-7.59116 -8.66539,-11.81643 -5.65756,-4.29689 -14.03649,-4.29689 h -12.1745 z" />
        </g>
      </g>
      <g
        aria-label="DEUCES   WILD"
        id="text4347-7"
        style={{ stroke: "#000000", strokeWidth: 2, fill: "#ffff00" }}
        transform="translate(-0.8482565,-11.02547)"
      >
        <g id="g5533-6" transform="translate(-30,14)">
          <path d="m 88.507233,126.14393 v -9.84378 l 8.072942,-1.82292 V 62.081228 l -8.072942,-1.875005 v -9.895865 h 8.072942 26.302165 q 10.20837,0 18.02089,4.791682 7.81252,4.739598 12.23962,13.177124 4.4271,8.385443 4.4271,19.322977 v 1.302088 q 0,10.885451 -4.37502,19.322981 -4.37501,8.38544 -12.18753,13.17712 -7.81253,4.7396 -18.02089,4.7396 z m 24.739657,-12.65629 h 8.8542 q 6.09377,0 10.20836,-3.12501 4.16668,-3.12501 6.30211,-8.64586 2.1875,-5.572935 2.1875,-12.812541 v -1.354171 q 0,-7.291689 -2.1875,-12.81254 -2.13543,-5.520851 -6.30211,-8.593777 -4.11459,-3.12501 -10.20836,-3.12501 h -8.8542 z" />
          <path d="m 163.87206,126.14393 v -9.84378 l 8.02085,-1.61459 V 61.820811 l -8.02085,-1.614588 v -9.895865 h 59.68768 v 21.1459 h -12.65629 l -0.57292,-8.33336 h -21.7709 v 17.395888 h 24.948 V 93.38341 h -24.948 v 19.94798 h 22.86466 l 0.57292,-8.64586 h 12.55212 v 21.4584 z" />
          <path d="m 270.22655,127.23768 q -9.37502,0 -16.45838,-3.12501 -7.03127,-3.12501 -10.88545,-9.11461 -3.85418,-6.04169 -3.85418,-14.68755 V 61.820811 l -7.96877,-1.614588 v -9.895865 h 32.60426 v 9.895865 l -7.96877,1.614588 v 38.489699 q 0,4.79169 1.71875,7.96878 1.71876,3.17709 4.94794,4.7396 3.28126,1.5625 7.81252,1.5625 4.68752,0 7.96878,-1.5625 3.28126,-1.56251 4.94793,-4.7396 1.71875,-3.17709 1.71875,-7.96878 V 61.820811 l -7.91669,-1.614588 v -9.895865 h 32.60427 v 9.895865 l -8.02086,1.614588 v 38.489699 q 0,8.5417 -3.95834,14.58338 -3.90627,6.04169 -10.93754,9.21878 -6.97919,3.12501 -16.35422,3.12501 z" />
          <path d="m 350.12264,127.23768 q -10.93754,0 -19.2709,-4.47918 -8.33336,-4.53126 -12.96879,-12.91671 -4.63543,-8.38544 -4.63543,-20.104225 v -2.968759 q 0,-10.989618 4.37502,-19.427144 4.37501,-8.48961 12.44795,-13.281292 8.12503,-4.843765 19.2709,-4.843765 7.81252,0 14.73962,2.135423 6.97919,2.135424 12.65629,6.927105 V 77.185442 H 364.49768 L 362.83101,65.83124 q -1.77084,-1.19792 -3.75001,-2.08334 -1.97917,-0.885419 -4.27085,-1.354171 -2.29167,-0.468751 -4.94793,-0.468751 -6.14585,0 -10.62503,2.968759 -4.47918,2.968759 -6.92711,8.48961 -2.44792,5.520851 -2.44792,13.281292 v 3.072926 q 0,7.500023 2.50001,13.125045 2.55209,5.57293 7.18752,8.64586 4.63543,3.07292 10.78128,3.07292 3.22918,0 6.45836,-0.67708 3.22917,-0.72917 6.04168,-1.87501 l 1.71876,-10.67712 h 12.23962 v 18.54173 q -4.47918,3.02084 -11.30212,5.20835 -6.77086,2.13542 -15.36463,2.13542 z" />
          <path d="m 382.7269,126.14393 v -9.84378 l 8.02086,-1.61459 V 61.820811 l -8.02086,-1.614588 v -9.895865 h 59.68768 v 21.1459 H 429.7583 l -0.57292,-8.33336 h -21.7709 v 17.395888 h 24.94799 V 93.38341 h -24.94799 v 19.94798 h 22.86465 l 0.57292,-8.64586 h 12.55212 v 21.4584 z" />
          <path d="m 482.88346,127.23768 q -8.80211,0 -15.83338,-1.82292 -7.03127,-1.82292 -13.90629,-6.56252 V 99.841763 h 12.86462 l 1.61459,10.468787 q 1.97917,1.92709 6.14585,3.17709 4.16668,1.25 9.11461,1.25 3.8021,0 6.40627,-0.98958 2.60418,-1.04167 3.95835,-2.91668 1.35417,-1.92709 1.35417,-4.53126 0,-2.50001 -1.25001,-4.4271 -1.19792,-1.979173 -4.01042,-3.645845 -2.81251,-1.666672 -7.60419,-3.281261 -9.37503,-2.864592 -15.4688,-6.041685 -6.04169,-3.229177 -9.06253,-7.604191 -2.96876,-4.427097 -2.96876,-10.833367 0,-6.197936 3.48959,-10.88545 3.54168,-4.739599 9.68753,-7.44794 6.14586,-2.708342 14.16671,-2.812509 9.01045,-0.208334 15.93755,1.927089 6.92711,2.135424 12.08338,6.145853 V 75.050019 H 497.20642 L 495.12308,64.78957 q -1.875,-1.093753 -5.20835,-1.927089 -3.28126,-0.833336 -7.29169,-0.833336 -3.43751,0 -6.09376,0.989586 -2.60418,0.937503 -4.16668,2.864593 -1.51043,1.875006 -1.51043,4.635431 0,2.23959 1.30209,4.010429 1.30209,1.770839 4.47918,3.437511 3.1771,1.614588 8.80211,3.593761 12.70838,3.645845 19.2709,9.375029 6.6146,5.729185 6.6146,15.260465 0,6.6146 -3.59376,11.3542 -3.54168,4.68752 -9.94795,7.18752 -6.40627,2.50001 -14.89588,2.50001 z" />
        </g>
        <g id="g5525-8" transform="translate(-20,-22)">
          <path d="m 175.11555,297.02547 -24.13417,-89.59005 -11.24352,-1.07423 v -13.6068 h 44.40115 v 13.6068 l -11.45836,1.64714 11.81643,48.76965 2.00521,8.09246 h 0.42969 l 2.22006,-8.09246 20.48182,-64.02359 h 16.32816 l 21.41281,66.31526 2.07683,6.51694 h 0.42969 l 1.71875,-6.51694 12.38935,-51.06132 -11.88805,-1.64714 v -13.6068 h 43.54177 v 13.6068 l -10.52737,1.07423 -24.7787,89.59005 h -20.62505 l -18.90629,-57.57826 -2.29167,-8.09247 h -0.42969 l -2.79298,8.37893 -19.19275,57.2918 z" />
          <path d="m 300.72781,297.02547 v -13.53519 l 11.02868,-2.22006 v -72.68897 l -11.02868,-2.22006 v -13.6068 h 44.83084 v 13.6068 l -10.95706,2.22006 v 72.68897 l 10.95706,2.22006 v 13.53519 z" />
          <path d="m 352.50528,297.02547 v -13.53519 l 11.02867,-2.22006 v -72.68897 l -11.02867,-2.22006 v -13.6068 h 11.02867 22.91672 12.81904 v 13.6068 l -12.81904,2.22006 v 70.8986 h 27.21361 l 0.78776,-14.96748 h 17.474 v 32.5131 z" />
          <path d="m 438.22812,297.02547 v -13.53519 l 11.10029,-2.50652 v -72.04443 l -11.10029,-2.57814 v -13.6068 h 11.10029 36.16544 q 14.0365,0 24.77871,6.58856 10.74221,6.51694 16.82946,18.11853 6.08726,11.52998 6.08726,26.56907 v 1.79037 q 0,14.96748 -6.01564,26.56907 -6.01564,11.52998 -16.75785,18.11854 -10.74222,6.51694 -24.77871,6.51694 z m 34.01701,-17.40239 h 12.1745 q 8.37893,0 14.03649,-4.29688 5.72918,-4.29689 8.66539,-11.88805 3.00782,-7.66278 3.00782,-17.61723 v -1.86198 q 0,-10.02607 -3.00782,-17.61723 -2.93621,-7.59116 -8.66539,-11.81643 -5.65756,-4.29689 -14.03649,-4.29689 h -12.1745 z" />
        </g>
      </g>
    </g>
  );
};
