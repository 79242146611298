export const JacksOrBetterTitle = () => {
  return (
    <>
      <g id="layer4" style={{ display: "inline", fill: "#ffff00" }}>
        <g
          aria-label="OR"
          style={{
            fontSize: 64,

            display: "inline",
            fill: "#1676d8",
            stroke: "#000000",
            strokeWidth: 18,
            strokeLinejoin: "round",
          }}
        >
          <path d="m 288.48828,162.02082 q 0,2.88 1.088,5.312 1.088,2.432 2.944,4.224 1.856,1.792 4.288,2.816 2.496,0.96 5.248,0.96 2.752,0 5.184,-0.96 2.496,-1.024 4.352,-2.816 1.92,-1.792 3.008,-4.224 1.088,-2.432 1.088,-5.312 0,-2.88 -1.088,-5.312 -1.088,-2.432 -3.008,-4.224 -1.856,-1.792 -4.352,-2.752 -2.432,-1.024 -5.184,-1.024 -2.752,0 -5.248,1.024 -2.432,0.96 -4.288,2.752 -1.856,1.792 -2.944,4.224 -1.088,2.432 -1.088,5.312 z m -13.12,0 q 0,-5.376 1.984,-9.984 1.984,-4.672 5.504,-8.128 3.52,-3.456 8.384,-5.376 4.928,-1.984 10.816,-1.984 5.824,0 10.752,1.984 4.928,1.92 8.448,5.376 3.584,3.456 5.568,8.128 1.984,4.608 1.984,9.984 0,5.376 -1.984,10.048 -1.984,4.608 -5.568,8.064 -3.52,3.456 -8.448,5.44 -4.928,1.92 -10.752,1.92 -5.888,0 -10.816,-1.92 -4.864,-1.984 -8.384,-5.44 -3.52,-3.456 -5.504,-8.064 -1.984,-4.672 -1.984,-10.048 z" />
          <path d="m 349.28823,159.46082 h 2.368 q 3.712,0 5.696,-1.536 1.984,-1.536 1.984,-4.416 0,-2.88 -1.984,-4.416 -1.984,-1.536 -5.696,-1.536 h -2.368 z m 27.584,26.688 h -15.616 l -11.968,-18.56 v 18.56 h -12.544 v -48.256 h 19.52 q 4.032,0 7.04,1.216 3.008,1.152 4.928,3.2 1.984,2.048 2.944,4.736 1.024,2.688 1.024,5.76 0,5.504 -2.688,8.96 -2.624,3.392 -7.808,4.608 z" />
        </g>
        <g
          aria-label="JACKS"
          style={{
            display: "inline",
            stroke: "#000000",
            strokeWidth: 18,
            strokeLinejoin: "round",
          }}
        >
          <path d="m 86.420901,49.649768 v 50.208 q 0,3.168002 -0.384,5.856002 -0.288,2.688 -1.056,4.608 -1.248,3.072 -3.456,5.664 -2.112,2.496 -4.992,4.32 -2.88,1.728 -6.432,2.688 -3.456,1.056 -7.296,1.056 -11.616,0 -18.912,-10.656 l 12.288,-12.576 q 0.48,3.264 2.112,5.184 1.632,1.92 4.032,1.92 5.28,0 5.28,-8.352002 v -49.92 z" />
          <path d="m 139.22069,95.153768 -8.448,-24.096 -8.448,24.096 z m 4.992,14.304002 h -26.88 l -4.32,12.576 H 92.948688 L 120.50069,49.649768 h 20.544 l 27.552,72.384002 h -20.064 z" />
          <path d="m 226.29234,73.841768 q -6.528,-7.968 -16.128,-7.968 -4.224,0 -7.872,1.536 -3.552,1.536 -6.144,4.224 -2.592,2.592 -4.128,6.24 -1.44,3.648 -1.44,7.872 0,4.32 1.44,7.968 1.536,3.648 4.128,6.336002 2.688,2.688 6.24,4.224 3.552,1.536 7.68,1.536 9.024,0 16.224,-7.680002 v 22.272002 l -1.92,0.672 q -4.32,1.536 -8.064,2.208 -3.744,0.768 -7.392,0.768 -7.488,0 -14.4,-2.784 -6.816,-2.88 -12.096,-7.968 -5.184,-5.184 -8.352,-12.192 -3.168,-7.104002 -3.168,-15.456002 0,-8.352 3.072,-15.264 3.168,-7.008 8.352,-12 5.28,-5.088 12.192,-7.872 6.912,-2.88 14.496,-2.88 4.32,0 8.448,0.96 4.224,0.864 8.832,2.784 z" />
          <path d="m 260.08428,79.505768 22.944,-29.856 h 23.232 l -28.704,34.272 31.392,38.112002 h -24.384 l -24.48,-31.392002 v 31.392002 h -18.816 V 49.649768 h 18.816 z" />
          <path d="m 357.04426,68.177768 q -3.072,-2.496 -6.144,-3.648 -3.072,-1.248 -5.952,-1.248 -3.648,0 -5.952,1.728 -2.304,1.728 -2.304,4.512 0,1.92 1.152,3.168 1.152,1.248 2.976,2.208 1.92,0.864 4.224,1.536 2.4,0.672 4.704,1.44 9.216,3.072 13.44,8.256 4.32,5.088 4.32,13.344 0,5.568002 -1.92,10.080002 -1.824,4.512 -5.472,7.776 -3.552,3.168 -8.832,4.896 -5.184,1.824 -11.808,1.824 -13.728,0 -25.44,-8.16 l 8.064,-15.168 q 4.224,3.744 8.352,5.568 4.128,1.824 8.16,1.824 4.608,0 6.816,-2.112 2.304,-2.112 2.304,-4.8 0,-1.632002 -0.576,-2.784002 -0.576,-1.248 -1.92,-2.208 -1.344,-1.056 -3.552,-1.92 -2.112,-0.864 -5.184,-1.92 -3.648,-1.152 -7.2,-2.496 -3.456,-1.44 -6.24,-3.744 -2.688,-2.304 -4.416,-5.76 -1.632,-3.552 -1.632,-8.928 0,-5.376 1.728,-9.696 1.824,-4.416 4.992,-7.488 3.264,-3.168 7.872,-4.896 4.704,-1.728 10.464,-1.728 5.376,0 11.232,1.536 5.856,1.44 11.232,4.32 z" />
        </g>
        <g
          aria-label="JACKS"
          id="text810-6"
          style={{
            display: "inline",
            stroke: "#1676d8",
            strokeWidth: 14,
            strokeLinejoin: "round",
          }}
        >
          <path d="m 86.420901,49.649768 v 50.208 q 0,3.168002 -0.384,5.856002 -0.288,2.688 -1.056,4.608 -1.248,3.072 -3.456,5.664 -2.112,2.496 -4.992,4.32 -2.88,1.728 -6.432,2.688 -3.456,1.056 -7.296,1.056 -11.616,0 -18.912,-10.656 l 12.288,-12.576 q 0.48,3.264 2.112,5.184 1.632,1.92 4.032,1.92 5.28,0 5.28,-8.352002 v -49.92 z" />
          <path d="m 139.22069,95.153768 -8.448,-24.096 -8.448,24.096 z m 4.992,14.304002 h -26.88 l -4.32,12.576 H 92.948688 L 120.50069,49.649768 h 20.544 l 27.552,72.384002 h -20.064 z" />
          <path d="m 226.29234,73.841768 q -6.528,-7.968 -16.128,-7.968 -4.224,0 -7.872,1.536 -3.552,1.536 -6.144,4.224 -2.592,2.592 -4.128,6.24 -1.44,3.648 -1.44,7.872 0,4.32 1.44,7.968 1.536,3.648 4.128,6.336002 2.688,2.688 6.24,4.224 3.552,1.536 7.68,1.536 9.024,0 16.224,-7.680002 v 22.272002 l -1.92,0.672 q -4.32,1.536 -8.064,2.208 -3.744,0.768 -7.392,0.768 -7.488,0 -14.4,-2.784 -6.816,-2.88 -12.096,-7.968 -5.184,-5.184 -8.352,-12.192 -3.168,-7.104002 -3.168,-15.456002 0,-8.352 3.072,-15.264 3.168,-7.008 8.352,-12 5.28,-5.088 12.192,-7.872 6.912,-2.88 14.496,-2.88 4.32,0 8.448,0.96 4.224,0.864 8.832,2.784 z" />
          <path d="m 260.08428,79.505768 22.944,-29.856 h 23.232 l -28.704,34.272 31.392,38.112002 h -24.384 l -24.48,-31.392002 v 31.392002 h -18.816 V 49.649768 h 18.816 z" />
          <path d="m 357.04426,68.177768 q -3.072,-2.496 -6.144,-3.648 -3.072,-1.248 -5.952,-1.248 -3.648,0 -5.952,1.728 -2.304,1.728 -2.304,4.512 0,1.92 1.152,3.168 1.152,1.248 2.976,2.208 1.92,0.864 4.224,1.536 2.4,0.672 4.704,1.44 9.216,3.072 13.44,8.256 4.32,5.088 4.32,13.344 0,5.568002 -1.92,10.080002 -1.824,4.512 -5.472,7.776 -3.552,3.168 -8.832,4.896 -5.184,1.824 -11.808,1.824 -13.728,0 -25.44,-8.16 l 8.064,-15.168 q 4.224,3.744 8.352,5.568 4.128,1.824 8.16,1.824 4.608,0 6.816,-2.112 2.304,-2.112 2.304,-4.8 0,-1.632002 -0.576,-2.784002 -0.576,-1.248 -1.92,-2.208 -1.344,-1.056 -3.552,-1.92 -2.112,-0.864 -5.184,-1.92 -3.648,-1.152 -7.2,-2.496 -3.456,-1.44 -6.24,-3.744 -2.688,-2.304 -4.416,-5.76 -1.632,-3.552 -1.632,-8.928 0,-5.376 1.728,-9.696 1.824,-4.416 4.992,-7.488 3.264,-3.168 7.872,-4.896 4.704,-1.728 10.464,-1.728 5.376,0 11.232,1.536 5.856,1.44 11.232,4.32 z" />
        </g>
        <g
          aria-label="JACKS"
          style={{
            baselineShift: "baseline",
            display: "inline",
            stroke: "#000000",
            strokeWidth: 2,
            strokeLinejoin: "round",
          }}
        >
          <path
            d="m 86.420901,49.649768 v 50.208 q 0,3.168002 -0.384,5.856002 -0.288,2.688 -1.056,4.608 -1.248,3.072 -3.456,5.664 -2.112,2.496 -4.992,4.32 -2.88,1.728 -6.432,2.688 -3.456,1.056 -7.296,1.056 -11.616,0 -18.912,-10.656 l 12.288,-12.576 q 0.48,3.264 2.112,5.184 1.632,1.92 4.032,1.92 5.28,0 5.28,-8.352002 v -49.92 z"
            style={{ baselineShift: "baseline" }}
          />
          <path
            d="m 139.22069,95.153768 -8.448,-24.096 -8.448,24.096 z m 4.992,14.304002 h -26.88 l -4.32,12.576 H 92.948688 L 120.50069,49.649768 h 20.544 l 27.552,72.384002 h -20.064 z"
            style={{ baselineShift: "baseline" }}
          />
          <path
            d="m 226.29234,73.841768 q -6.528,-7.968 -16.128,-7.968 -4.224,0 -7.872,1.536 -3.552,1.536 -6.144,4.224 -2.592,2.592 -4.128,6.24 -1.44,3.648 -1.44,7.872 0,4.32 1.44,7.968 1.536,3.648 4.128,6.336002 2.688,2.688 6.24,4.224 3.552,1.536 7.68,1.536 9.024,0 16.224,-7.680002 v 22.272002 l -1.92,0.672 q -4.32,1.536 -8.064,2.208 -3.744,0.768 -7.392,0.768 -7.488,0 -14.4,-2.784 -6.816,-2.88 -12.096,-7.968 -5.184,-5.184 -8.352,-12.192 -3.168,-7.104002 -3.168,-15.456002 0,-8.352 3.072,-15.264 3.168,-7.008 8.352,-12 5.28,-5.088 12.192,-7.872 6.912,-2.88 14.496,-2.88 4.32,0 8.448,0.96 4.224,0.864 8.832,2.784 z"
            style={{ baselineShift: "baseline" }}
          />
          <path
            d="m 260.08428,79.505768 22.944,-29.856 h 23.232 l -28.704,34.272 31.392,38.112002 h -24.384 l -24.48,-31.392002 v 31.392002 h -18.816 V 49.649768 h 18.816 z"
            style={{ baselineShift: "baseline" }}
          />
          <path
            d="m 357.04426,68.177768 q -3.072,-2.496 -6.144,-3.648 -3.072,-1.248 -5.952,-1.248 -3.648,0 -5.952,1.728 -2.304,1.728 -2.304,4.512 0,1.92 1.152,3.168 1.152,1.248 2.976,2.208 1.92,0.864 4.224,1.536 2.4,0.672 4.704,1.44 9.216,3.072 13.44,8.256 4.32,5.088 4.32,13.344 0,5.568002 -1.92,10.080002 -1.824,4.512 -5.472,7.776 -3.552,3.168 -8.832,4.896 -5.184,1.824 -11.808,1.824 -13.728,0 -25.44,-8.16 l 8.064,-15.168 q 4.224,3.744 8.352,5.568 4.128,1.824 8.16,1.824 4.608,0 6.816,-2.112 2.304,-2.112 2.304,-4.8 0,-1.632002 -0.576,-2.784002 -0.576,-1.248 -1.92,-2.208 -1.344,-1.056 -3.552,-1.92 -2.112,-0.864 -5.184,-1.92 -3.648,-1.152 -7.2,-2.496 -3.456,-1.44 -6.24,-3.744 -2.688,-2.304 -4.416,-5.76 -1.632,-3.552 -1.632,-8.928 0,-5.376 1.728,-9.696 1.824,-4.416 4.992,-7.488 3.264,-3.168 7.872,-4.896 4.704,-1.728 10.464,-1.728 5.376,0 11.232,1.536 5.856,1.44 11.232,4.32 z"
            style={{ baselineShift: "baseline" }}
          />
        </g>
        <g
          aria-label="BETTER"
          id="text810-7"
          style={{
            display: "inline",
            stroke: "#000000",
            strokeWidth: 18,
            strokeLinejoin: "round",
          }}
        >
          <path d="m 272.66971,252.34794 h 3.66933 q 6.31466,0 9.04533,-1.62133 2.73066,-1.62133 2.73066,-5.20533 0,-3.584 -2.73066,-5.20533 -2.73067,-1.62134 -9.04533,-1.62134 h -3.66933 z m 0,-26.11199 h 3.07199 q 7.85067,0 7.85067,-6.22933 0,-6.22933 -7.85067,-6.22933 h -3.07199 z M 255.94438,200.9773 h 24.91732 q 8.87467,0 13.48266,4.26666 4.608,4.26667 4.608,12.288 0,4.86399 -1.792,8.10666 -1.70666,3.15733 -5.29066,5.376 3.584,0.68266 6.05866,2.13333 2.56,1.36533 4.096,3.41333 1.62133,2.048 2.304,4.608 0.68267,2.56 0.68267,5.46133 0,4.52267 -1.62134,8.02133 -1.536,3.49867 -4.43733,5.888 -2.816,2.38933 -6.912,3.584 -4.09599,1.19466 -9.21599,1.19466 h -26.87999 z" />
          <path d="M 351.60296,215.14262 H 331.7203 v 10.752 h 18.77333 v 14.16533 H 331.7203 v 11.09333 h 19.88266 v 14.16532 h -36.60799 v -64.3413 h 36.60799 z" />
          <path d="M 388.04025,215.14262 V 265.3186 H 371.31493 V 215.14262 H 357.57627 V 200.9773 h 44.20265 v 14.16532 z" />
          <path d="M 433.60809,215.14262 V 265.3186 H 416.88277 V 215.14262 H 403.14411 V 200.9773 h 44.20265 v 14.16532 z" />
          <path d="m 491.37861,215.14262 h -19.88266 v 10.752 h 18.77333 v 14.16533 h -18.77333 v 11.09333 h 19.88266 v 14.16532 h -36.60798 v -64.3413 h 36.60798 z" />
          <path d="m 520.13591,229.73462 h 3.15733 q 4.94933,0 7.59466,-2.048 2.64534,-2.048 2.64534,-5.888 0,-3.84 -2.64534,-5.888 -2.64533,-2.048 -7.59466,-2.048 h -3.15733 z m 36.77865,35.58398 h -20.82133 l -15.95732,-24.74665 v 24.74665 h -16.72533 v -64.3413 h 26.02666 q 5.37599,0 9.38666,1.62133 4.01066,1.536 6.57066,4.26666 2.64534,2.73067 3.92534,6.31467 1.36533,3.584 1.36533,7.68 0,7.33866 -3.584,11.94666 -3.49867,4.52266 -10.41066,6.14399 z" />
        </g>
        <g
          aria-label="BETTER"
          style={{
            fontSize: 85.3333,

            display: "inline",
            stroke: "#1676d8",
            strokeWidth: 15,
            strokeLinejoin: "round",
          }}
        >
          <path d="m 272.66971,252.34794 h 3.66933 q 6.31466,0 9.04533,-1.62133 2.73066,-1.62133 2.73066,-5.20533 0,-3.584 -2.73066,-5.20533 -2.73067,-1.62134 -9.04533,-1.62134 h -3.66933 z m 0,-26.11199 h 3.07199 q 7.85067,0 7.85067,-6.22933 0,-6.22933 -7.85067,-6.22933 h -3.07199 z M 255.94438,200.9773 h 24.91732 q 8.87467,0 13.48266,4.26666 4.608,4.26667 4.608,12.288 0,4.86399 -1.792,8.10666 -1.70666,3.15733 -5.29066,5.376 3.584,0.68266 6.05866,2.13333 2.56,1.36533 4.096,3.41333 1.62133,2.048 2.304,4.608 0.68267,2.56 0.68267,5.46133 0,4.52267 -1.62134,8.02133 -1.536,3.49867 -4.43733,5.888 -2.816,2.38933 -6.912,3.584 -4.09599,1.19466 -9.21599,1.19466 h -26.87999 z" />
          <path d="M 351.60296,215.14262 H 331.7203 v 10.752 h 18.77333 v 14.16533 H 331.7203 v 11.09333 h 19.88266 v 14.16532 h -36.60799 v -64.3413 h 36.60799 z" />
          <path d="M 388.04025,215.14262 V 265.3186 H 371.31493 V 215.14262 H 357.57627 V 200.9773 h 44.20265 v 14.16532 z" />
          <path d="M 433.60809,215.14262 V 265.3186 H 416.88277 V 215.14262 H 403.14411 V 200.9773 h 44.20265 v 14.16532 z" />
          <path d="m 491.37861,215.14262 h -19.88266 v 10.752 h 18.77333 v 14.16533 h -18.77333 v 11.09333 h 19.88266 v 14.16532 h -36.60798 v -64.3413 h 36.60798 z" />
          <path d="m 520.13591,229.73462 h 3.15733 q 4.94933,0 7.59466,-2.048 2.64534,-2.048 2.64534,-5.888 0,-3.84 -2.64534,-5.888 -2.64533,-2.048 -7.59466,-2.048 h -3.15733 z m 36.77865,35.58398 h -20.82133 l -15.95732,-24.74665 v 24.74665 h -16.72533 v -64.3413 h 26.02666 q 5.37599,0 9.38666,1.62133 4.01066,1.536 6.57066,4.26666 2.64534,2.73067 3.92534,6.31467 1.36533,3.584 1.36533,7.68 0,7.33866 -3.584,11.94666 -3.49867,4.52266 -10.41066,6.14399 z" />
        </g>
        <g
          aria-label="BETTER"
          style={{
            fontSize: 85.3333,

            display: "inline",
            stroke: "#000000",
            strokeWidth: 2,
            strokeLinejoin: "round",
          }}
        >
          <path d="m 272.66971,252.34794 h 3.66933 q 6.31466,0 9.04533,-1.62133 2.73066,-1.62133 2.73066,-5.20533 0,-3.584 -2.73066,-5.20533 -2.73067,-1.62134 -9.04533,-1.62134 h -3.66933 z m 0,-26.11199 h 3.07199 q 7.85067,0 7.85067,-6.22933 0,-6.22933 -7.85067,-6.22933 h -3.07199 z M 255.94438,200.9773 h 24.91732 q 8.87467,0 13.48266,4.26666 4.608,4.26667 4.608,12.288 0,4.86399 -1.792,8.10666 -1.70666,3.15733 -5.29066,5.376 3.584,0.68266 6.05866,2.13333 2.56,1.36533 4.096,3.41333 1.62133,2.048 2.304,4.608 0.68267,2.56 0.68267,5.46133 0,4.52267 -1.62134,8.02133 -1.536,3.49867 -4.43733,5.888 -2.816,2.38933 -6.912,3.584 -4.09599,1.19466 -9.21599,1.19466 h -26.87999 z" />
          <path d="M 351.60296,215.14262 H 331.7203 v 10.752 h 18.77333 v 14.16533 H 331.7203 v 11.09333 h 19.88266 v 14.16532 h -36.60799 v -64.3413 h 36.60799 z" />
          <path d="M 388.04025,215.14262 V 265.3186 H 371.31493 V 215.14262 H 357.57627 V 200.9773 h 44.20265 v 14.16532 z" />
          <path d="M 433.60809,215.14262 V 265.3186 H 416.88277 V 215.14262 H 403.14411 V 200.9773 h 44.20265 v 14.16532 z" />
          <path d="m 491.37861,215.14262 h -19.88266 v 10.752 h 18.77333 v 14.16533 h -18.77333 v 11.09333 h 19.88266 v 14.16532 h -36.60798 v -64.3413 h 36.60798 z" />
          <path d="m 520.13591,229.73462 h 3.15733 q 4.94933,0 7.59466,-2.048 2.64534,-2.048 2.64534,-5.888 0,-3.84 -2.64534,-5.888 -2.64533,-2.048 -7.59466,-2.048 h -3.15733 z m 36.77865,35.58398 h -20.82133 l -15.95732,-24.74665 v 24.74665 h -16.72533 v -64.3413 h 26.02666 q 5.37599,0 9.38666,1.62133 4.01066,1.536 6.57066,4.26666 2.64534,2.73067 3.92534,6.31467 1.36533,3.584 1.36533,7.68 0,7.33866 -3.584,11.94666 -3.49867,4.52266 -10.41066,6.14399 z" />
        </g>
        <g
          aria-label="OR"
          style={{
            fontSize: 64,

            display: "inline",
            fill: "#1676d8",
            stroke: "#1676d8",
            strokeWidth: 15,
            strokeLinejoin: "round",
          }}
        >
          <path d="m 288.48828,162.02082 q 0,2.88 1.088,5.312 1.088,2.432 2.944,4.224 1.856,1.792 4.288,2.816 2.496,0.96 5.248,0.96 2.752,0 5.184,-0.96 2.496,-1.024 4.352,-2.816 1.92,-1.792 3.008,-4.224 1.088,-2.432 1.088,-5.312 0,-2.88 -1.088,-5.312 -1.088,-2.432 -3.008,-4.224 -1.856,-1.792 -4.352,-2.752 -2.432,-1.024 -5.184,-1.024 -2.752,0 -5.248,1.024 -2.432,0.96 -4.288,2.752 -1.856,1.792 -2.944,4.224 -1.088,2.432 -1.088,5.312 z m -13.12,0 q 0,-5.376 1.984,-9.984 1.984,-4.672 5.504,-8.128 3.52,-3.456 8.384,-5.376 4.928,-1.984 10.816,-1.984 5.824,0 10.752,1.984 4.928,1.92 8.448,5.376 3.584,3.456 5.568,8.128 1.984,4.608 1.984,9.984 0,5.376 -1.984,10.048 -1.984,4.608 -5.568,8.064 -3.52,3.456 -8.448,5.44 -4.928,1.92 -10.752,1.92 -5.888,0 -10.816,-1.92 -4.864,-1.984 -8.384,-5.44 -3.52,-3.456 -5.504,-8.064 -1.984,-4.672 -1.984,-10.048 z" />
          <path d="m 349.28823,159.46082 h 2.368 q 3.712,0 5.696,-1.536 1.984,-1.536 1.984,-4.416 0,-2.88 -1.984,-4.416 -1.984,-1.536 -5.696,-1.536 h -2.368 z m 27.584,26.688 h -15.616 l -11.968,-18.56 v 18.56 h -12.544 v -48.256 h 19.52 q 4.032,0 7.04,1.216 3.008,1.152 4.928,3.2 1.984,2.048 2.944,4.736 1.024,2.688 1.024,5.76 0,5.504 -2.688,8.96 -2.624,3.392 -7.808,4.608 z" />
        </g>
        <g
          aria-label="OR"
          style={{
            fontSize: 64,
            display: "inline",
            stroke: "#000000",
            strokeWidth: 2,
            strokeLinejoin: "round",
          }}
        >
          <path d="m 288.48828,162.02082 q 0,2.88 1.088,5.312 1.088,2.432 2.944,4.224 1.856,1.792 4.288,2.816 2.496,0.96 5.248,0.96 2.752,0 5.184,-0.96 2.496,-1.024 4.352,-2.816 1.92,-1.792 3.008,-4.224 1.088,-2.432 1.088,-5.312 0,-2.88 -1.088,-5.312 -1.088,-2.432 -3.008,-4.224 -1.856,-1.792 -4.352,-2.752 -2.432,-1.024 -5.184,-1.024 -2.752,0 -5.248,1.024 -2.432,0.96 -4.288,2.752 -1.856,1.792 -2.944,4.224 -1.088,2.432 -1.088,5.312 z m -13.12,0 q 0,-5.376 1.984,-9.984 1.984,-4.672 5.504,-8.128 3.52,-3.456 8.384,-5.376 4.928,-1.984 10.816,-1.984 5.824,0 10.752,1.984 4.928,1.92 8.448,5.376 3.584,3.456 5.568,8.128 1.984,4.608 1.984,9.984 0,5.376 -1.984,10.048 -1.984,4.608 -5.568,8.064 -3.52,3.456 -8.448,5.44 -4.928,1.92 -10.752,1.92 -5.888,0 -10.816,-1.92 -4.864,-1.984 -8.384,-5.44 -3.52,-3.456 -5.504,-8.064 -1.984,-4.672 -1.984,-10.048 z" />
          <path d="m 349.28823,159.46082 h 2.368 q 3.712,0 5.696,-1.536 1.984,-1.536 1.984,-4.416 0,-2.88 -1.984,-4.416 -1.984,-1.536 -5.696,-1.536 h -2.368 z m 27.584,26.688 h -15.616 l -11.968,-18.56 v 18.56 h -12.544 v -48.256 h 19.52 q 4.032,0 7.04,1.216 3.008,1.152 4.928,3.2 1.984,2.048 2.944,4.736 1.024,2.688 1.024,5.76 0,5.504 -2.688,8.96 -2.624,3.392 -7.808,4.608 z" />
        </g>
      </g>
    </>
  );
};
